import {
	Button,
	Heading,
	Icon,
	Input,
	InputGroup,
	InputLeftElement,
	Stack,
	Text,
	Wrap,
	WrapItem,
} from "@chakra-ui/react";
import { FaMapPin } from "react-icons/fa6";
import { useNavigate } from "react-router";
import { DottedCard } from "../../../components/DottedCard";

export default function BookAServiceSection() {
	const navigate = useNavigate();

	return (
		<DottedCard background="#F4DDCB" width="360px" height="100%" minWidth="300px">
			<Wrap spacing="20px" direction="column">
				<Heading as="h3" size="xl">
					Book a service
				</Heading>
				<Text fontSize="md" fontWeight="bold">
					Enter a post code to see all the services nearby
				</Text>
				<Wrap display="flex" alignItems="end" direction="row" flexWrap="wrap" gap="20px">
					<WrapItem>
						<Stack spacing="8px">
							<Text>Codice postale</Text>
							<InputGroup>
								<InputLeftElement pointerEvents="none">
									<Icon as={FaMapPin} color="#344054" />
								</InputLeftElement>
								<Input backgroundColor="white" type="number" />
							</InputGroup>
						</Stack>
					</WrapItem>
					<WrapItem alignItems="flex-end">
						<Button
							background="#D14B64"
							w="144px"
							h="52px"
							textColor="white"
							onClick={() => navigate("/find")}>
							Find services
						</Button>
					</WrapItem>
				</Wrap>
			</Wrap>
		</DottedCard>
	);
}
