import { defineStyleConfig } from "@chakra-ui/react";

export const Button = defineStyleConfig({
	baseStyle: {
		fontWeight: "semibold",
		textTransform: "capitalize",
		borderRadius: "8px",
	},
	sizes: {
		sm: {
			fontSize: "sm",
			px: 4,
			py: 3,
		},
		md: {
			fontSize: "md",
			px: 6,
			py: 4,
		},
		lg: {
			fontSize: "lg",
			px: 6,
			py: 4,
		},
	},
	variants: {
		primary: {
			bg: "primary.500",
			color: "white",
			_hover: {
				bg: "primary.600",
			},
			fontWeight: "semibold",
		},
		secondary: {
			bg: "secondary.500",
			color: "black",
			_hover: {
				bg: "secondary.600",
			},
			fontWeight: "semibold",
		},
		blue: {
			bg: "blue.500",
			color: "white",
			_hover: {
				bg: "blue.600",
			},
			fontWeight: "semibold",
		},
		outlined: {
			bg: "transparent",
			color: "primary.500",
			border: "2px solid black",
			_hover: {
				bg: "primary.500",
				color: "white",
			},
		},
	},
	defaultProps: {
		size: "lg",
		variant: "primary",
	},
});
