import { FormLabel, extendTheme } from "@chakra-ui/react";
import { Button } from "./Button";
import { Input } from "./Input";

export const theme = extendTheme({
	components: {
		Button,
		Input,
		FormLabel,
	},
	colors: {
		primary: {
			500: "#312C29",
			600: "#9E0037",
		},
		secondary: {
			500: "#FFFFFF",
			600: "#F7F5F3",
			700: "#EDE9E4",
		},
		blue: {
			500: "#3B5FCE",
			600: "#2E4AA0",
		},
		black: {
			500: "#312C29",
		},
	},
});
