import { Box, Card, Center, Flex, Heading, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { FaPencil, FaRegCalendar } from "react-icons/fa6";
import { FaRegClock } from "react-icons/fa6";
import { FaRegCircleQuestion } from "react-icons/fa6";

export const OrderSummary = () => {
	return (
		<Center flexDirection="column" gap="40px">
			<Heading color="#8C152B"> Order summary</Heading>
			<Stack
				direction="column"
				width="100%"
				maxW="650px"
				gap="20px"
				justifyContent="center"
				alignItems="center"
				flexWrap="wrap">
				<Card padding="20px" flexDir="column" width="100%" gap="10px">
					<Flex flexDirection="row">
						<Text fontSize="18px" color="#2E4AA0">
							Item 1
						</Text>
					</Flex>

					<Flex flexDirection="row" justifyContent="space-between" alignItems="center">
						<Text fontSize="18px" color="black" fontWeight="bold">
							Jacket
						</Text>
						<Text fontSize="18px" color="black" fontWeight="bold">
							$100.00
						</Text>
					</Flex>
					<Flex flexDirection="row">
						<Text fontSize="18px">Full details</Text>
					</Flex>
				</Card>

				<Card padding="20px" flexDir="column" width="100%" gap="10px">
					<Flex flexDirection="row">
						<Text fontSize="18px" color="#2E4AA0">
							Merchant
						</Text>
					</Flex>

					<Flex flexDirection="row" justifyContent="space-between" alignItems="center">
						<Text fontSize="18px" color="black" fontWeight="bold">
							Dress Making Services Ltd
						</Text>
						<FaPencil />
					</Flex>

					<Flex flexDirection="row">
						<Text fontSize="18px" color="#2E4AA0">
							Collection
						</Text>
					</Flex>

					<Flex flexDirection="row" justifyContent="space-between" alignItems="center">
						<Flex alignItems="center" gap="8px">
							<FaRegCalendar color="#CBC6C0" fontSize="20px" />
							<Text fontSize="18px" color="black" fontWeight="bold">
								April 6
							</Text>

							<FaRegClock color="#CBC6C0" fontSize="20px" />
							<Text fontSize="18px" color="black" fontWeight="bold">
								12.00
							</Text>
						</Flex>

						<FaPencil fontSize="20px" />
					</Flex>

					<Flex flexDirection="row">
						<Text fontSize="18px" color="#2E4AA0">
							Delivery
						</Text>
					</Flex>

					<Flex flexDirection="row" alignItems="center" gap="5px" justifyContent="space-between">
						<Flex alignItems="center" gap="8px">
							<FaRegCalendar color="#CBC6C0" fontSize="20px" />
							<Text fontSize="18px" color="black" fontWeight="bold">
								April 6
							</Text>
						</Flex>
						<FaRegCircleQuestion fontSize="20px" />
					</Flex>
				</Card>

				<Box
					padding="20px"
					flexDir="column"
					width="100%"
					gap="10px"
					background="unset"
					border="unset">
					<Flex flexDirection="row" justifyContent="space-between" alignItems="center">
						<Text fontSize="18px" color="black" fontWeight="bold">
							Cost
						</Text>
						<Text fontSize="18px">$100.00</Text>
					</Flex>

					<Flex flexDirection="row" justifyContent="space-between" alignItems="center">
						<Text fontSize="18px" color="black" fontWeight="bold">
							Shipping
						</Text>

						<Text fontSize="18px" color="black" fontWeight="bold">
							$3.00
						</Text>
					</Flex>

					<Flex flexDirection="row" alignItems="center" gap="5px" justifyContent="space-between">
						<Flex alignItems="center" gap="8px">
							<Text fontSize="18px" color="black" fontWeight="bold">
								Services fees
							</Text>
							<FaRegCircleQuestion fontSize="20px" />
						</Flex>
						<Text fontSize="18px" color="black" fontWeight="bold">
							$2.00
						</Text>
					</Flex>

					<Flex flexDirection="row" alignItems="center" gap="5px" justifyContent="space-between">
						<Flex flexDirection="column">
							<Text fontSize="18px" color="black" fontWeight="bold">
								Total
							</Text>
							<Text fontSize="14px" color="small">
								VAT included
							</Text>
						</Flex>
						<Text size="lg" color="black" fontWeight="bold">
							$105.00
						</Text>
					</Flex>
				</Box>
			</Stack>
		</Center>
	);
};
