import { Box, Center, Text } from "@chakra-ui/react";
import React from "react";

export const HowItWorks = () => {
	return (
		<Box bg="white" h="350px" p="20px" w="100%">
			<Center bg="#ECE5E2" h="250px" w="100%" color="white"></Center>
			<Text fontSize="2xl" fontWeight="bold">
				How it works
			</Text>
			<Text fontSize="lg">Description</Text>
		</Box>
	);
};
