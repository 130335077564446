import {
	Button,
	Center,
	Flex,
	FormControl,
	FormHelperText,
	FormLabel,
	Heading,
	Input,
	InputGroup,
	InputRightElement,
	Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { Field, Form } from "react-final-form";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import { Link } from "react-router-dom";

import { useGoogleLogin } from "@react-oauth/google";
interface FormValues {
	email: string;
	password: string;
}
export const Signup = () => {
	const [showPassword, setShowPassword] = useState(false);
	const handleClick = () => setShowPassword(!showPassword);

	const login = useGoogleLogin({
		onSuccess: (res) => handleGoogleLogin(res.access_token),
		onError: (error) => console.log("Login Failed:", error),
	});

	const handleGoogleLogin = async (res: string) => {
		try {
			const response = await fetch(
				`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${res}`,
				{
					method: "GET",
					headers: {
						Authorization: `Bearer ${res}`,
						"Content-Type": "application/json",
					},
				}
			);

			if (response.status === 200) {
				const data = await response.json();
				console.log(data);
			}
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<Flex w="100%" justifyContent="center">
			<Center flexDirection="column" gap="40px" alignItems="center" w="70%">
				<Heading color="#8C152B" textAlign="left" w="100%">
					Signup
				</Heading>

				<Form<FormValues>
					onSubmit={(values) => console.log(values)}
					render={() => (
						<Flex flexDirection="row" alignItems="start" w="100%" gap="10px" flexWrap="wrap">
							<Field
								name="email"
								render={({ input }) => (
									<FormControl maxW={300}>
										<FormLabel>Email address</FormLabel>
										<Input placeholder="E-Mail" type="email" {...input} />
									</FormControl>
								)}
							/>
							<Field
								name="password"
								render={({ input }) => (
									<FormControl maxW={300}>
										<FormLabel>Password</FormLabel>
										<InputGroup>
											<Input
												placeholder="Password"
												type={showPassword ? "text" : "password"}
												{...input}
											/>
											<InputRightElement>
												{showPassword ? (
													<FaEyeSlash onClick={handleClick} />
												) : (
													<FaEye onClick={handleClick} />
												)}
											</InputRightElement>
										</InputGroup>
									</FormControl>
								)}
							/>
							<FormControl maxW={300}>
								<FormLabel height="25px"></FormLabel>
								<Button width="250px" fontSize="small" fontWeight="300">
									Sign up
								</Button>
								<FormHelperText>
									<Flex gap={1}>
										Already have an account?
										<Link to="/login" color="blue">
											<Text color="blue">Log in here</Text>
										</Link>
									</Flex>
								</FormHelperText>
							</FormControl>
						</Flex>
					)}
				/>

				<Text textTransform="uppercase">or</Text>
				<Flex gap={2}>
					<button
						className="gsi-material-button"
						style={{ width: "300px" }}
						onClick={() => login()}>
						<div className="gsi-material-button-state"></div>
						<div className="gsi-material-button-content-wrapper">
							<div className="gsi-material-button-icon">
								<svg viewBox="0 0 48 48" style={{ display: "block" }}>
									<path
										fill="#EA4335"
										d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
									<path
										fill="#4285F4"
										d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
									<path
										fill="#FBBC05"
										d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
									<path
										fill="#34A853"
										d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
									<path fill="none" d="M0 0h48v48H0z"></path>
								</svg>
							</div>
							<span className="gsi-material-button-contents">Sign up with Google</span>
						</div>
					</button>
				</Flex>
				<Text fontSize="12px">
					By registering, you are agreeing to the Terms & Conditions and Privacy & Cookies Policy{" "}
				</Text>
			</Center>
		</Flex>
	);
};
