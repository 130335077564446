import { Center, Heading, Stack } from "@chakra-ui/react";
import { useForm, useFormState } from "react-final-form";
import { FormValues } from "../FindServices";
import { ShopCard } from "./ShopCard";

export interface ShopType {
	id: string;
	name: string;
	category: string[];
}

const shops: ShopType[] = [
	{
		id: "1",
		name: "Tailornova Ltd",
		category: ["Tailor"],
	},
	{
		id: "2",
		name: "Tailornova Ltd",
		category: ["Tailor"],
	},
	{
		id: "3",
		name: "Tailornova Ltd",
		category: ["Tailor"],
	},
	{
		id: "4",
		name: "Tailornova Ltd",
		category: ["Tailor"],
	},
	{
		id: "5",
		name: "Tailornova Ltd",
		category: ["Tailor"],
	},
	{
		id: "6",
		name: "Tailornova Ltd",
		category: ["Tailor"],
	},
];

export const ShopsSection = () => {
	const form = useForm<FormValues>();
	const { values } = useFormState<FormValues>({
		subscription: { values: true },
	});

	return (
		<Center flexDirection="column" gap="40px">
			<Heading color="#8C152B"> Scegli una sartoria locale</Heading>
			<Stack direction="row" width="100%" justifyContent="center" flexWrap="wrap">
				{shops.map((shop) => (
					<ShopCard
						key={shop.id}
						shop={shop}
						selected={values?.shop?.id === shop.id}
						onClick={() => form.change("shop", shop)}
					/>
				))}
			</Stack>
		</Center>
	);
};
