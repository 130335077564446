import { Button, ButtonGroup, Center } from "@chakra-ui/react";
import { FaArrowLeft } from "react-icons/fa6";

interface Props {
	hideBackButton?: boolean;
	hideContinueButton?: boolean;
	continueButtonDisabled?: boolean;
	onBackClick?: () => void;
	onContinueClick?: () => void;
}

export const Footer: React.FC<Props> = ({
	hideBackButton,
	hideContinueButton,
	continueButtonDisabled,
	onBackClick,
	onContinueClick,
}) => {
	return (
		<Center flexDirection="column" gap="40px">
			<ButtonGroup gap="4">
				<Button
					background="white"
					color="black"
					visibility={hideBackButton ? "hidden" : "visible"}
					width="182px"
					h="56px"
					leftIcon={<FaArrowLeft />}
					onClick={onBackClick}>
					Back
				</Button>

				<Button
					background="#D14B64"
					color="white"
					width="182px"
					h="56px"
					disabled={continueButtonDisabled}
					visibility={hideContinueButton ? "hidden" : "visible"}
					onClick={onContinueClick}>
					Continue
				</Button>
			</ButtonGroup>
		</Center>
	);
};
