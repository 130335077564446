import { Heading, Stack, Wrap, WrapItem } from "@chakra-ui/react";
import { ServiceCard } from "./ServiceCard";
import { Cleaning } from "../../../assets/images/Cleaning";
import Repair from "../../../assets/images/Repair";
import BookAServiceSection from "./BookAServiceSection";

export interface ServiceCardType {
	title: string;
	description: string;
	image: React.ReactElement;
}

const services: ServiceCardType[] = [
	{
		title: "Cleaning",
		description: "Service 1 description",
		image: <Cleaning />,
	},
	{
		title: "Repair",
		description: "Service 2 description",
		image: <Repair />,
	},
	{
		title: "Alterations",
		description: "Service 3 description",
		image: <></>,
	},
];

export const ServicesSection = () => {
	return (
		<Stack flexDirection="row" spacing="30px" flexWrap="wrap" alignItems="flex-end">
			<Stack flexDirection="column" justifyContent="flex-end" flex={2}>
				<Heading as="h2" size="lg" noOfLines={1}>
					Our Services
				</Heading>
				<Heading as="h3" size="md" noOfLines={1}>
					Description
				</Heading>
				<Wrap spacing="30px" align="flex-end" justifyContent="space-between">
					{services.map((service) => (
						<WrapItem flex="auto">
							<ServiceCard service={service} />
						</WrapItem>
					))}
				</Wrap>
			</Stack>
			<BookAServiceSection />
		</Stack>
	);
};
