import { Navigate, Routes as ReactRoutes, Route, BrowserRouter as Router } from "react-router-dom";
import Homepage from "./views/Homepage/Homepage";
import { FindServices } from "./views/PlaceOrder/FindServices";
import { OrderComplete } from "./views/PlaceOrder/OrderComplete";
import { Shop } from "./views/Shop/Shop";
import { Login } from "./views/Login/Login";
import { Signup } from "./views/Signup/Signup";

export const Routes = () => {
	return (
		<Router>
			<ReactRoutes>
				<Route path="/" element={<Homepage />} />
				<Route path="/find" element={<FindServices />} />
				<Route path="/shop" element={<Shop />} />
				<Route path="/complete" element={<OrderComplete />} />
				<Route path="/login" element={<Login />} />
				<Route path="/signup" element={<Signup />} />
				<Route path="*" element={<Navigate to="/" />} />
			</ReactRoutes>
		</Router>
	);
};
