import "./App.css";

import { ChakraProvider } from "@chakra-ui/react";
import { Routes } from "./Routes";
import { ContainerHeader } from "./components/ContainerHeader";
import { ContainerPage } from "./components/ContainerPage";
import { Header } from "./components/Header";
import { theme } from "./theme/theme";

import { GoogleOAuthProvider } from "@react-oauth/google";
function App() {
	return (
		<ChakraProvider theme={theme}>
			<ContainerHeader>
				<Header />
			</ContainerHeader>
			<GoogleOAuthProvider clientId="301719468401-dtpjot6frta8pig784cq8h7ksgo0ha5t.apps.googleusercontent.com">
				<ContainerPage>
					<Routes />
				</ContainerPage>
			</GoogleOAuthProvider>
		</ChakraProvider>
	);
}

export default App;
