import { Heading, Stack, Wrap, WrapItem } from "@chakra-ui/react";
import React from "react";
import { HowItWorks } from "./HowItWorks";
import FAQs from "./FAQs";

export const HowItWorksSection = () => {
	return (
		<Stack>
			<Heading as="h2" size="lg" noOfLines={1}>
				How it works
			</Heading>
			<Heading as="h3" size="md" noOfLines={1}>
				Description
			</Heading>
			<Wrap justify="space-between" flex="1" spacing="40px">
				<WrapItem flex="auto" gap="20px">
					<HowItWorks />
				</WrapItem>

				<WrapItem flex="auto">
					<FAQs />
				</WrapItem>
			</Wrap>
		</Stack>
	);
};
