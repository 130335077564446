import { Badge, Box, Heading, Image, Stack, Text } from "@chakra-ui/react";

export const Shop = () => {
	return (
		<Box display="flex" gap="20px" flexDirection="column">
			<Heading as="h1" size="2xl">
				Tailornova Ltd
			</Heading>
			<Stack direction="row" overflow="auto">
				<Image
					src="https://source.unsplash.com/random/400x400?sig=1"
					alt="Shop Logo"
					borderRadius="8px"
					w={["300px", "400px"]}
					h={["200px", "300px"]}
				/>
				<Image
					src="https://source.unsplash.com/random/400x400?sig=2"
					alt="Shop Logo"
					borderRadius="8px"
					w={["300px", "400px"]}
					h={["200px", "300px"]}
				/>
				<Image
					src="https://source.unsplash.com/random/400x400?sig=3"
					alt="Shop Logo"
					borderRadius="8px"
					w={["300px", "400px"]}
					h={["200px", "300px"]}
				/>
				<Image
					src="https://source.unsplash.com/random/400x400?sig=4"
					alt="Shop Logo"
					borderRadius="8px"
					w={["300px", "400px"]}
					h={["200px", "300px"]}
				/>
				<Image
					src="https://source.unsplash.com/random/400x400?sig=5"
					alt="Shop Logo"
					borderRadius="8px"
					w={["300px", "400px"]}
					h={["200px", "300px"]}
				/>
				<Image
					src="https://source.unsplash.com/random/400x400?sig=6"
					alt="Shop Logo"
					borderRadius="8px"
					w={["300px", "400px"]}
					h={["200px", "300px"]}
				/>
				<Image
					src="https://source.unsplash.com/random/400x400?sig=7"
					alt="Shop Logo"
					borderRadius="8px"
					w={["300px", "400px"]}
					h={["200px", "300px"]}
				/>
				<Image
					src="https://source.unsplash.com/random/400x400?sig=8"
					alt="Shop Logo"
					borderRadius="8px"
					w={["300px", "400px"]}
					h={["200px", "300px"]}
				/>
			</Stack>
			<Stack direction="row" overflow="auto">
				<Badge
					backgroundColor="#F3E3D6"
					color="#3D3D3D"
					w="98px"
					h="28px"
					display="flex"
					alignItems="center"
					justifyContent="center"
					borderRadius="16px">
					Repairs
				</Badge>
				<Badge
					backgroundColor="#F3E3D6"
					color="#3D3D3D"
					w="98px"
					h="28px"
					display="flex"
					alignItems="center"
					justifyContent="center"
					borderRadius="16px">
					Alterations
				</Badge>
			</Stack>
			<Heading as="h2" size="lg">
				Merchant description
			</Heading>
			<Text>
				Description about the merchant...Forgot your password? We can help with thatForgot your
				password? We can help with thatForgot your password? We can help with that
			</Text>
		</Box>
	);
};
