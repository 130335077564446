import { Heading, Stack } from "@chakra-ui/react";
import { ServicesSection } from "./ServicesSection/ServicesSection";
import { HowItWorksSection } from "./HowItWorksSection/HowItWorksSection";

export default function Homepage() {
	return (
		<Stack spacing="48px">
			<Heading as="h1" size="2xl" noOfLines={1}>
				Welcome!
			</Heading>

			<ServicesSection />

			<HowItWorksSection />
		</Stack>
	);
}
