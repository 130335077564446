import { defineStyleConfig } from "@chakra-ui/react";

export const Input = defineStyleConfig({
	baseStyle: {
		fontWeight: "semibold",
		borderRadius: "8px",
	},
	sizes: {
		sm: {
			fontSize: "sm",
			px: 4,
			py: 3,
		},
		md: {
			fontSize: "md",
			px: 6,
			py: 4,
		},
		lg: {
			fontSize: "lg",
			px: 6,
			py: 4,
		},
	},
	variants: {
		primary: {
			bg: "primary.500",
			color: "white",
			_hover: {
				bg: "primary.600",
			},
			fontWeight: "semibold",
		},
	},
	defaultProps: {
		size: "lg",
		variant: "primary",
	},
});
