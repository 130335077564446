import { Box, Center, Text } from "@chakra-ui/react";
import React from "react";
import { ServiceCardType } from "./ServicesSection";
interface Props {
	service: ServiceCardType;
}

export const ServiceCard: React.FC<Props> = ({ service }) => {
	return (
		<Box bg="white" w="100%" h="263px" p="20px" cursor="pointer" minWidth="250px">
			<Center bg="#ECE5E2" h="150px" color="white">
				{service.image}
			</Center>
			<Text fontSize="2xl" fontWeight="bold">
				{service.title}
			</Text>
			<Text fontSize="lg">{service.description}</Text>
		</Box>
	);
};
