import { Button, Center, Heading, Text } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router";
import { Cleaning } from "../../assets/images/Cleaning";
import { useEffect } from "react";

export const OrderComplete = () => {
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		// if (!location.state) navigate("/");
	}, [location, navigate]);

	return (
		<Center flexDirection="column" gap="10px">
			<Cleaning />

			<Heading color="#8C152B">Thanks for your order</Heading>

			<Text fontSize="14px">
				Your request has been submitted to the {location?.state?.merchantName} You will get an email
				with details for the collection of your Jacket
			</Text>

			<Button onClick={() => navigate("/")}>Homepage</Button>
		</Center>
	);
};
