import {
	Accordion,
	AccordionButton,
	AccordionItem,
	AccordionPanel,
	Box,
	Icon,
	Stack,
	Text,
} from "@chakra-ui/react";
import { FaAngleRight } from "react-icons/fa6";

interface CustomAccordionProps {
	label: React.ReactNode;
	content: React.ReactNode;
}

const CustomAccordioItem: React.FC<CustomAccordionProps> = ({ label, content }) => {
	return (
		<AccordionItem borderColor="transparent">
			<AccordionButton p={0}>
				<Stack direction="row" flex="1" spacing="20px" align="center">
					<Icon as={FaAngleRight} fontSize="20px" />

					<Box as="span" textAlign="left" fontWeight="bold">
						{label}
					</Box>
				</Stack>
			</AccordionButton>
			<AccordionPanel pb={4} maxWidth="300px">
				{content}
			</AccordionPanel>
		</AccordionItem>
	);
};

export default function FAQs() {
	return (
		<Box bg="white" w="100%" h="350px" p="20px">
			<Stack spacing="20px" direction="row" h="100%">
				<Stack flex="auto" h="100%" w="100%">
					<Stack>
						<Text fontSize="2xl" fontWeight="bold">
							Our top FAQs
						</Text>
						<Text fontSize="lg">Description</Text>
					</Stack>

					<Stack flex="auto" justifyContent="center" h="100%" w="100%">
						<Accordion allowToggle borderColor="transparent">
							<Stack direction="column" spacing="20px">
								<CustomAccordioItem
									label={"What can i get serviced"}
									content={
										"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt"
									}
								/>
								<CustomAccordioItem
									label={"Something else"}
									content={
										"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt"
									}
								/>
								<CustomAccordioItem
									label={"Something else"}
									content={
										"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt"
									}
								/>
								<CustomAccordioItem
									label={"Veiw all"}
									content={
										"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt"
									}
								/>
							</Stack>
						</Accordion>
					</Stack>
				</Stack>
				<Box bg="#ECE5E2" w="100%" h="100%" borderRadius="4px"></Box>
			</Stack>
		</Box>
	);
}
