import { Heading, IconButton } from "@chakra-ui/react";
import { FaCartShopping } from "react-icons/fa6";

export const Header = () => {
	return (
		<>
			<Heading as="h1" size="lg" noOfLines={1} color="#D14B64">
				Concoora
			</Heading>
			<IconButton
				aria-label="Open cart"
				icon={<FaCartShopping />}
				size="lg"
				color="#D14B64"
				variant="ghost"
			/>
		</>
	);
};
