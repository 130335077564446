import {
	Button,
	Center,
	Heading,
	Input,
	InputGroup,
	InputRightElement,
	Link,
	Text,
} from "@chakra-ui/react";
import { Field, useForm } from "react-final-form";

export const PostalCodeSection = () => {
	const form = useForm();

	return (
		<>
			<Center flexDirection="column" gap="20px">
				<Heading color="#8C152B"> Qual'è il tuo codice fiscale?</Heading>
				<Field
					name="postalCode"
					render={({ input }) => (
						<InputGroup size="md" maxW="400px" backgroundColor="white" h="56px">
							<Input pr="4.5rem" type="number" h="56px" {...input} />
							<InputRightElement width="6.5rem" justifyContent="center" h="100%">
								<Button
									h="70%"
									size="lg"
									background="#D14B64"
									textColor="white"
									onClick={() => form.change("state", "ITEM_TYPE")}>
									Cerca
								</Button>
							</InputRightElement>
						</InputGroup>
					)}
				/>

				<Text>
					Non trovi la tua città?
					<Link color="#D14B64" href="#" paddingStart="5px">
						Faccelo sapere!
					</Link>
				</Text>
			</Center>
		</>
	);
};
