import { Box, BoxProps } from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";

interface Props extends BoxProps, PropsWithChildren {}

export const DottedCard: React.FC<Props> = ({ children, ...props }) => {
	return (
		<Box padding="10px" borderRadius="8px" {...props}>
			<Box w="100%" height="100%" borderRadius="4px" border="2px dashed #D14B64" padding="16px">
				{children}
			</Box>
		</Box>
	);
};
