import { Center, Heading, Tag, TagLabel, Wrap, WrapItem } from "@chakra-ui/react";
import { useForm, useFormState } from "react-final-form";
import { FormValues } from "../FindServices";

export interface ItemType {
	name: string;
	id: string;
}

const itemTypeList: ItemType[] = [
	{
		id: "1",
		name: "Trousers",
	},
	{
		id: "2",
		name: "Skirts",
	},
	{
		id: "3",
		name: "Dress",
	},
	{
		id: "4",
		name: "Top",
	},
	{
		id: "5",
		name: "Sweatshirt",
	},
	{
		id: "6",
		name: "Hoodie",
	},
	{
		id: "7",
		name: "Knitwear",
	},
	{
		id: "8",
		name: "Shirt",
	},
	{
		id: "9",
		name: "Blazer",
	},
	{
		id: "10",
		name: "Jumpsuit",
	},
	{
		id: "11",
		name: "Jeans",
	},
	{
		id: "12",
		name: "Puffer jacket",
	},
	{
		id: "13",
		name: "Coat",
	},
	{
		id: "14",
		name: "Gilet",
	},
	{
		id: "15",
		name: "Jacket",
	},
];

export const ItemTypeSection = () => {
	const form = useForm<FormValues>();

	const { values } = useFormState<FormValues>({
		subscription: {
			values: true,
		},
	});

	return (
		<Center flexDirection="column" gap="40px">
			<Heading color="#8C152B"> Cosa vorresti riparare?</Heading>

			<Wrap maxW="500px" gap={0.75} justifyContent="center">
				{itemTypeList.map((item) => (
					<WrapItem
						borderColor="unset"
						justifyContent="center"
						onClick={() => form.change("itemType", item)}>
						{values.itemType?.id === item.id ? (
							<Tag
								variant="solid"
								backgroundColor="#4463C5"
								cursor="pointer"
								justifyContent="center"
								borderRadius="36px"
								textColor="black"
								h="48px"
								size="lg"
								minW="100px">
								<TagLabel color="white">{item.name}</TagLabel>
							</Tag>
						) : (
							<Tag
								variant="outline"
								cursor="pointer"
								colorScheme="blackAlpha"
								justifyContent="center"
								borderRadius="36px"
								boxShadow="unset"
								border="2px solid #3D3D3D"
								textColor="black"
								h="48px"
								size="lg"
								minW="100px">
								<TagLabel>{item.name}</TagLabel>
							</Tag>
						)}
					</WrapItem>
				))}
			</Wrap>
		</Center>
	);
};
