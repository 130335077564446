import { Box, Button, Flex, Heading, Tag, TagLabel } from "@chakra-ui/react";
import { FaPlus } from "react-icons/fa6";
import { DottedCard } from "../../../components/DottedCard";
import { ShopType } from "./ShopsSection";
import { FaRegCalendar } from "react-icons/fa6";

import { FaPenToSquare } from "react-icons/fa6";

interface Props {
	shop: ShopType;
	selected: boolean;
	onClick?: () => void;
}

export const ShopCard: React.FC<Props> = ({ shop, selected = false, onClick }) => {
	return (
		<DottedCard
			padding="10px"
			borderRadius="8px"
			w="300px"
			background="white"
			border={selected ? "2px solid #9E0037" : "unset"}>
			<Flex
				flexDirection="column"
				alignItems="flex-start"
				justifyContent="space-between"
				h="100%"
				gap="12px">
				<Flex flexDirection="row" justify="flex-start" align="flex-start" gap="10px">
					<img src={require("../../../assets/images/Rectangle 4.png")} alt="Shop logo" />
					{shop.category.map((category) => (
						<Tag
							justifyContent="center"
							borderRadius="36px"
							background="#EDE9E4"
							textColor="black"
							h="32px"
							size="lg">
							<TagLabel>{category}</TagLabel>
						</Tag>
					))}
				</Flex>
				<Heading fontSize="x-large" fontWeight="bold">
					{shop.name}
				</Heading>
				<Heading fontSize="large" fontWeight="bold">
					Full Details
				</Heading>

				{selected ? (
					<Box
						w="100%"
						border="1px solid #D0D5DD"
						gap="12px"
						borderRadius="8px"
						flexDirection="row"
						justifyContent="space-between"
						padding="12px 24px 12px 24px"
						rowGap="10px"
						display="flex"
						cursor="pointer"
						alignItems="center">
						<FaRegCalendar fontSize="22px" />
						<Flex flexDir="column" width="100%" justifyContent="center" alignItems="center">
							<Heading fontSize="medium" fontWeight="bold" color="#2E4AA0">
								Collection
							</Heading>
							<Heading fontSize="small" fontWeight="bold">
								{Math.random() > 0.5 ? "Set period" : "Apr 6 - 18:00"}
							</Heading>
						</Flex>
						<FaPenToSquare fontSize="22px" />
					</Box>
				) : (
					<Button variant="primary" width="100%" size="md" onClick={onClick}>
						<Flex gap="8px" align="center">
							<FaPlus />
							Select
						</Flex>
					</Button>
				)}
			</Flex>
		</DottedCard>
	);
};
