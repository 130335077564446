import { Stack } from "@chakra-ui/react";
import { FormApi } from "final-form";
import { Form } from "react-final-form";
import { match } from "ts-pattern";
import { Footer } from "./Footer/Footer";
import { Header } from "./Header/Header";
import { ItemType, ItemTypeSection } from "./ItemTypeSection/ItemTypeSection";
import { PostalCodeSection } from "./PostalCodeSection/PostalCodeSection";
import { ServiceTypeSection } from "./ServiceTypeSection/ServiceTypeSection";
import { ShopType, ShopsSection } from "./ShopsSection/ShopsSection";
import { OrderSummary } from "./OrderSummary/OrderSummary";

export type StepType = "POSTAL_CODE" | "ITEM_TYPE" | "SERVICE_TYPE" | "SHOPS" | "ORDER_SUMMARY";

export interface FormValues {
	state: StepType;
	postalCode?: number;
	itemType?: ItemType;
	serviceType?: [];
	shop?: ShopType;
}

type FooterProps = React.ComponentProps<typeof Footer>;

export const FindServices = () => {
	const calculateFooterProps = (values: FormValues, form: FormApi<FormValues>): FooterProps => {
		return match(values.state)
			.with("POSTAL_CODE", () => ({
				hideBackButton: true,
				hideContinueButton: true,
			}))
			.with("ITEM_TYPE", () => ({
				hideBackButton: false,
				onContinueClick: () => form.change("state", "SERVICE_TYPE"),
				onBackClick: () => form.change("state", "POSTAL_CODE"),
				continueButtonDisabled: !values?.itemType,
			}))
			.with("SERVICE_TYPE", () => ({
				hideBackButton: false,
				onContinueClick: () => form.change("state", "SHOPS"),
				onBackClick: () => form.change("state", "ITEM_TYPE"),
				continueButtonDisabled: !values?.serviceType?.length,
			}))
			.with("SHOPS", () => ({
				hideBackButton: false,
				onContinueClick: () => form.change("state", "ORDER_SUMMARY"),
				onBackClick: () => form.change("state", "SERVICE_TYPE"),
				continueButtonDisabled: !values?.serviceType?.length,
			}))
			.with("ORDER_SUMMARY", () => ({
				hideBackButton: false,
				onContinueClick: () => form.change("state", "ORDER_SUMMARY"),
				onBackClick: () => form.change("state", "SHOPS"),
				continueButtonDisabled: true,
			}))
			.exhaustive();
	};

	return (
		<>
			<Form<FormValues>
				onSubmit={(values) => console.log(values)}
				initialValues={{
					state: "POSTAL_CODE",
				}}
				render={({ values, form }) => (
					<Stack spacing="30px">
						<Header />
						{match(values.state)
							.with("POSTAL_CODE", () => <PostalCodeSection />)
							.with("ITEM_TYPE", () => <ItemTypeSection />)
							.with("SERVICE_TYPE", () => <ServiceTypeSection />)
							.with("SHOPS", () => <ShopsSection />)
							.with("ORDER_SUMMARY", () => <OrderSummary />)
							.exhaustive()}
						<Footer {...calculateFooterProps(values, form)} />
					</Stack>
				)}
			/>
		</>
	);
};
