import { Center, Heading, Stack } from "@chakra-ui/react";
import { ServiceCard } from "./ServiceCard";

export interface ServiceType {
	name: string;
	id: string;
}

const serviceList: ServiceType[] = [
	{
		name: "Cleaning",
		id: "1",
	},
	{
		name: "Repair",
		id: "2",
	},
	{
		name: "Alteration",
		id: "3",
	},
	{
		name: "Cleaning",
		id: "4",
	},
	{
		name: "Repair",
		id: "5",
	},
	{
		name: "Alteration",
		id: "6",
	},
];

export const ServiceTypeSection = () => {
	return (
		<Center flexDirection="column" gap="40px">
			<Heading color="#8C152B"> Che tipo di servizio hai bisogno?</Heading>
			<Stack direction="row" width="100%" justifyContent="center" flexWrap="wrap">
				{serviceList.map((item) => (
					<ServiceCard service={item} key={item.id} />
				))}
			</Stack>
		</Center>
	);
};
