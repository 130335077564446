import { Breadcrumb, BreadcrumbItem, Flex, Icon, IconButton } from "@chakra-ui/react";
import { FaCircle, FaXmark } from "react-icons/fa6";
import { useNavigate } from "react-router";

import { useFormState } from "react-final-form";
import { Permutations } from "../../../components/utilities";
import { FormValues, StepType } from "../FindServices";

type StatusArray = Permutations<StepType>;

const steps: StatusArray = ["POSTAL_CODE", "ITEM_TYPE", "SERVICE_TYPE", "SHOPS", "ORDER_SUMMARY"];

export const Header = () => {
	const navigate = useNavigate();

	const { values } = useFormState<FormValues>({
		subscription: {
			values: true,
		},
	});

	return (
		<Flex flex={1} flexDirection="row" alignItems="center" justifyContent="space-between">
			<Breadcrumb separator="" spacing="20px">
				{steps.map((step) => (
					<>
						{step === values.state ? (
							<BreadcrumbItem marginRight="5px">
								<Icon as={FaCircle} w="12px" h="12px" color="#4463C5" />
							</BreadcrumbItem>
						) : (
							<BreadcrumbItem marginRight="5px">
								<Icon as={FaCircle} w="10px" h="10px" color="#9db1ef" />
							</BreadcrumbItem>
						)}
					</>
				))}
			</Breadcrumb>
			<IconButton
				icon={<FaXmark color="black" />}
				backgroundColor="white"
				isRound
				aria-label="Close search"
				size="lg"
				onClick={() => navigate("/")}
				fontSize={20}
			/>
		</Flex>
	);
};
