import { Button, Center, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { FaPlus } from "react-icons/fa6";
import { Cleaning } from "../../../assets/images/Cleaning";
import { DottedCard } from "../../../components/DottedCard";
import { ServiceType } from "./ServiceTypeSection";

interface Props {
	service: ServiceType;
}

export const ServiceCard: React.FC<Props> = ({ service }) => {
	return (
		<DottedCard h="180px" maxW="300px" background="white" width="100%">
			<Center
				flexDirection="column"
				width="100%"
				h="100%"
				alignItems="center"
				justifyContent="space-between">
				<Flex flexDirection="row" align="center" justifyContent="space-between" h="100%" gap="10px">
					<Cleaning width="80px" height="60px" />
					<Flex flexDirection="column" alignItems="center" justifyContent="center">
						<Text fontSize="2xl" fontWeight="bold">
							{service.name}
						</Text>
						<Text fontSize="lg">Description</Text>
					</Flex>
				</Flex>
				<Button variant="blue" width="100%" size="md" leftIcon={<FaPlus />}>
					Select
				</Button>
			</Center>
		</DottedCard>
	);
};
